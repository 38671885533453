<template>
  <div>
    <portal to="page-top-title">Edit List #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'contactLists-single', params: { id: id } }" icon="fa-eye" text>Back to List</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="contactLists-list" :url="`/contact-lists/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-select name="category_id" title="Category" :model="formData.category_id"
                               :options="categories" :disabled="!editable.category || !categories.length" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active" :disabled="!editable.active"/>
          </column-input>
        </row>
        <row>
          <column-input>
            <form-group-input name="custom_id" :model="formData.custom_id" title="Custom ID"/>
          </column-input>
          <column-input>
            <form-group-input type="number" name="price" :model="formData.price" :min="0" :step="0.01" :max="9999999.99"/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import Form from '@/modules/utils/Form'

export default {
  name: 'ContactListsEdit',
  metaInfo () {
    return { title: `ContactList #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    FormGroupInput,
    FormGroupCheck,
    FormGroupSelect
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      loaded: false,
      categories: [],
      editable: {},
      formData: {
        name: Form.types.input(),
        category_id: Form.types.select(),
        price: Form.types.number(),
        custom_id: Form.types.input(),
        active: Form.types.boolean()
      }
    }
  },
  created () {
    this.loadEntity()
    this.loadCategories()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/contact-lists/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.name.value = data.name
          this.formData.active.value = data.active
          this.formData.custom_id.value = data.custom_id
          this.formData.price.value = data.price
          this.formData.category_id.value = { id: data.category.id, name: data.category.name }
          this.editable = data.metadata.editable

          this.loaded = true
        })
    },
    loadCategories () {
      this.$http
        .get('/contact-lists/categories', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.categories = data.map((category) => { return { id: category.id, name: category.name } })
        })
    }
  }
}
</script>
